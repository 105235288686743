import React from "react";
import { connect } from "react-redux";
import { requestUpdate } from "../../redux/actions/updateActions";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./Update.css";

const RequestUpdate = ({ requestUpdate }) => {
  function handleConfirm() {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => requestUpdate(),
        },
        {
          label: "No",
          onClick: () => {
            return false;
          },
        },
      ],
    });
  }
  return (
    <span
      className="btn btn-primary btn-update"
      onClick={() => handleConfirm()}
    >
      Update Content
    </span>
  );
};

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = {
  requestUpdate,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestUpdate);
