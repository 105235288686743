import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  loadSeasons,
  setCurrentSeason,
} from "../../redux/actions/seasonActions";
import { loadStyles } from "../../redux/actions/styleActions";
import StyleList from "./List";
import Spinner from "../shared/Spinner";
import Seasons from "./Season";
import { Redirect } from "react-router-dom";

const categoryId = process.env.REACT_APP_MAIN_CATEGORY;
const initialSeason = process.env.REACT_APP_INITIAL_CATEGORY;

function Index({
  styles,
  currency,
  history,
  customer,
  addresses,
  cart,
  seasons,
  loadStyles,
  loadSeasons,
  currentSeason,
  setCurrentSeason,
  auth,
  adminAuth,
  ...props
}) {
  const [style, setStyle] = useState({});
  const [season, setSeason] = useState(
    currentSeason ? currentSeason : initialSeason
  );
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState(false);

  useEffect(() => {
    if (adminAuth) {
      auth = adminAuth;
    }
    setSeason(currentSeason);
    if (!styles || styles === null || styles.length === 0) {
      loadStyles(categoryId, auth, currency).catch((error) => {
        setApiError(true);
      });
    } else {
      setStyle({ ...props.style });
    }

    if (!seasons || seasons === null || seasons.length === 0) {
      loadSeasons(auth).catch((error) => {
        setApiError(true);
      });
    }
  }, []);

  function getColour(item) {
    let colourId = item.custom_attributes.find((obj) => {
      return obj.attribute_code === "group_colour";
    });

    if (colourId && colourId.value) {
      return colourId.value;
    }
  }

  function getStyleList(styles) {
    let seasonStyles = getStylesForSeason(styles);

    let mainStyleList = getMainStyles(seasonStyles);

    return mainStyleList;
  }

  function getStylesForSeason(mainStyleList) {
    let styleList = [];

    mainStyleList.map((item) => {
      let styleSeason = item.extension_attributes.category_links.find((obj) => {
        return obj.category_id != categoryId;
      });

      let styleSeasonCats = item.extension_attributes.category_links.filter(
        function (catLink) {
          return catLink.category_id != categoryId;
        }
      );
      if (styleSeason && styleSeason.category_id) {
        let is_item_exist = styleSeasonCats.find((seasonCat) => {
          return seasonCat.category_id == season;
        });
        if (is_item_exist && is_item_exist.category_id) {
          styleList.push(item);
        }
        // if (styleSeason.category_id === season) {
        //   styleList.push(item);
        // }
      }
    });
    return styleList;
  }

  function getMainStyles(styles) {
    let styleList = [];
    let styleListNew = [];

    let showSkus = [];

    styles.map((item, i, array) => {
      let parentSku = item.custom_attributes.find((obj) => {
        return obj.attribute_code === "parent_sku";
      });
      let show_in_styles = item.custom_attributes.find((obj) => {
        return obj.attribute_code === "show_in_styles";
      });

      if (parentSku && parentSku.value) {
        if (!showSkus.includes(parentSku.value)) {
          // console.log('parent sku item',item);
          styleList.push(item);
          showSkus.push(parentSku.value);
        }
      }
      if (show_in_styles && show_in_styles.value == 5933) {
        styleListNew.push(item);
      }
    });
    const newStyleList = styleList.concat(styleListNew);
    return newStyleList;
  }

  function handleChange(event) {
    setSeason(event.target.value);
    setCurrentSeason(event.target.value);
  }

  // function getPreviousStyle(index, array) {
  //   if (array[index - 1]) {
  //     let sku = array[index - 1].custom_attributes.find((obj) => {
  //       return obj.attribute_code === "parent_sku";
  //     }).value;
  //     return sku;
  //   }
  //   return false;
  // }

  if (!customer || Object.keys(customer).length < 2) {
    return <Redirect to="/customer" />;
  }

  return !styles || styles.length === 0 || styles === null || apiError ? (
    <Spinner />
  ) : (
    <>
      <h1>Styles</h1>
      <Seasons
        seasons={seasons}
        seasonId={season}
        cart={cart}
        onChange={handleChange}
        errors={errors}
      />
      <StyleList
        styles={getStyleList(styles)}
        currency={currency}
        getColour={getColour}
      />
    </>
  );
}

function mapStateToProps(state) {
  console.log("state", state);
  return {
    styles: state.styles,
    currency: state.currency,
    seasons: state.seasons,
    customer: state.customer,
    addresses: state.addresses,
    auth: state.auth,
    adminAuth: state.adminAuth,
    currentSeason: state.currentSeason,
    cart: state.cart,
  };
}

const mapDispatchToProps = {
  loadStyles,
  loadSeasons,
  setCurrentSeason,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
