import React from "react";
import PropTypes from "prop-types";
import Colours from "./Colours";
import Addresses from "./Addresses";
import Sizes from "./Sizes";
import Dates from "./Dates";
import DeliverySeason from "./Season";

const Form = ({
  onSave,
  saving,
  addresses,
  onChange,
  getOptionLabel,
  errors,
  style,
  date,
  colours,
  address,
  currency,
  onClick,
  sizes,
  deliverySeason,
  season,
  currentSeason,
  setColour,
  handleCheckout,
  cart,
  seasons
}) => {
  return (
    <form onSubmit={onSave}>
      {errors.onSave && (
        <div className="alert alert-danger" role="alert">
          {errors.onSave}
        </div>
      )}
      <Colours
        colours={colours}
        onChange={onChange}
        getOptionLabel={getOptionLabel}
        errors={errors}
        setColour={setColour}
      />
      <Sizes
        style={style}
        sizes={sizes}
        onChange={onChange}
        onClick={onClick}
        errors={errors}
        currency={currency}
        currentSeason={currentSeason}
      />
      <Addresses
        addresses={addresses}
        cart={cart}
        onChange={onChange}
        address={address}
        errors={errors}
      />
      <Dates style={style} date={date} onChange={onChange} errors={errors} season={currentSeason} seasons={seasons}  />
      <DeliverySeason
        deliverySeason={deliverySeason}
        onChange={onChange}
        errors={errors}
        season={season}
      />
      <input type="hidden" name="style" value={style} />
      <a
        onClick={handleCheckout}
        disabled={saving}
        className="btn btn-primary btn-next btn-link-custom mg-left"
      >
        {saving ? "Adding..." : "Checkout"}
      </a>
      <button
        type="submit"
        disabled={saving}
        className="btn btn-primary btn-next"
      >
        {saving ? "Adding..." : "Add Style"}
      </button>
    </form>
  );
};

Form.propTypes = {
  style: PropTypes.object.isRequired,
  colours: PropTypes.array.isRequired,
};

export default Form;
