import React, { useEffect, useState } from "react";
import "../../order/Index.css";
import { connect } from "react-redux";
import { Panel, Grid, Row, Col } from "rsuite";
import { useParams, useHistory } from "react-router-dom";
import { getReturnById } from "../../../api/orderApi";
import "../Index.css";

const categoryId = process.env.REACT_APP_MAIN_CATEGORY;

function Index({ auth }) {
  const [orderData, setOrderData] = useState([]);
  const { Id } = useParams();
  let history = useHistory();

  useEffect(() => {
    if (orderData.length == 0) {
      getReturnById(Id, auth).then((response) => {
        console.log("response", response);
        setOrderData(response);
      });
    }
  });
  const handleClick = () => {
    history.replace("/returns");
  };
  return (
    <>
      <Grid fluid>
        <Row gutter={16}>
          <Col xs={24}>
            <Panel
              bordered
              style={{ width: "80%", textAlign: "center", margin: "0 auto" }}
            >
              <h1 style={{ color: "green" }}>Return Generated</h1>
              <h2 style={{ color: "gray", marginBottom: "20px" }}>
                {orderData.length > 0 && orderData[0].RMA}
              </h2>
              <p>
                Provide this RMA number to the retailer. <br></br>
                The RMA number should be printed and placed inside the shipped
                package
              </p>
              <h4 style={{ textAlign: "left" }}>Return Items</h4>
              {orderData.length > 0 && (
                <>
                  <table className="table ret_table">
                    <thead>
                      <tr>
                        <th>Sku</th>
                        <th>Style</th>
                        <th>Colour</th>
                        <th>Size</th>
                        <th>Qty</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderData.map((item, i) => {
                        return (
                          <tr key={"orderData" + i}>
                            <td>{item.Sku}</td>
                            <td>{item.Style}</td>
                            <td>{item.Colour}</td>
                            <td>{item.Size}</td>
                            <td>{item.ReturnQty}</td>
                            <td>
                              {item.Currency[0]}
                              {item.ReturnValue}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <span style={{ fontSize: "20px" }}>Return Value :</span>
                  <span
                    style={{
                      fontSize: "30px",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {orderData[0].Currency[0]}
                    {orderData[0].TotalValue}
                  </span>
                </>
              )}
              <div>
                <a
                  className="btn btn-primary btn-checkout"
                  onClick={handleClick}
                  style={{ marginTop: "10px" }}
                >
                  Continue
                </a>
              </div>
            </Panel>
          </Col>
        </Row>
      </Grid>
    </>
  );
}
function mapStateToProps(state, ownProps) {
  return {
    auth: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
