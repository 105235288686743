import React from "react";
import NumberInput from "../shared/NumberInput";
import "./Sizes.css";

const Sizes = ({
  style,
  onChange,
  sizes,
  errors = {},
  currency,
  currentSeason,
}) => {
  let type = getFootwearType(style);

  return (
    <div class="sizes">
      <h3>Sizes</h3>
      {getSizing()}
    </div>
  );

  function getSizing() {
    console.log("type", type);
    switch (type) {
      case "insole":
        return insoleSizing();
      case "shoe":
        if (parseInt(currentSeason) == 70 && currency == "$") {
          return euSizing();
        } else {
          return ukSizing("women");
        }
      case "mensshoe":
        if (parseInt(currentSeason) == 70 && currency == "$") {
          return euSizing();
        } else {
          return ukSizing("men");
        }
      case "sandal":
        if (parseInt(currentSeason) == 70 && currency == "$") {
          return euSizing();
        } else {
          return ukSizing("women");
        }
      case "elevatedsandal":
        if (parseInt(currentSeason) == 70 && currency == "$") {
          return euSizing();
        } else {
          return ukSizing("women");
        }
      case "slipper":
        if (parseInt(currentSeason) == 70 && currency == "$") {
          return euSizing();
        } else {
          return ukSizing("women");
        }
      case "mensslipper":
        if (parseInt(currentSeason) == 70 && currency == "$") {
          return euSizing();
        } else {
          return ukSizing("men");
        }
      case "pos":
        if (parseInt(currentSeason) == 70 && currency == "$") {
          return euSizing();
        } else {
          return ukSizing("women");
        }
      default:
        if (parseInt(currentSeason) == 70 && currency == "$") {
          return euSizing();
        } else {
          return ukSizing();
        }
    }
  }

  function ukSizing(gender = "") {
    let for_women = 0;
    // if (currency === "$") {
    //   style.product_links.map((size, index) => {
    //     let lengthToCut = getLengthToCut(size);
    //     if (parseFloat(size.linked_product_sku.slice(lengthToCut)) + 2 < 7) {
    //       for_women = 1;
    //       return;
    //     }
    //   });
    // }
    if (gender === "women") {
      for_women = 1;
    }

    console.log("gender", gender);
    console.log("style", style);
    return style.product_links.map((size, index) => {
      let lengthToCut = getLengthToCut(size);
      let label = "";
      let to_show = 1;
      if (for_women === 1) {
        label = getSizeLabelWomen(size, lengthToCut, type);
      } else {
        label = getSizeLabelMen(size, lengthToCut, type);
      }
      if (label == "EU null") {
        to_show = 0;
      }
      return currency === "$" ? (
        to_show ? (
          <>
            <NumberInput
              key={index}
              name={"size " + size.linked_product_sku.slice(lengthToCut)}
              label={label}
              placeholder="0"
              value={
                sizes["size " + size.linked_product_sku.slice(lengthToCut)]
              }
              onChange={onChange}
              error={errors.sizes}
            />
          </>
        ) : null
      ) : (
        <>
          <NumberInput
            key={index}
            name={
              "size " +
              size.linked_product_sku.slice(lengthToCut).replace("-", "")
            }
            label={getSizeLabel(size, lengthToCut, type).replace("-", "")}
            placeholder="0"
            value={
              sizes[
                "size " +
                  size.linked_product_sku.slice(lengthToCut).replace("-", "")
              ]
            }
            onChange={onChange}
            error={errors.sizes}
          />
        </>
      );
    });
  }

  function euSizing() {
    console.log("styles euSizing", style);
    return style.product_links.map((size, index) => {
      let lengthToCut = getLengthToCut(size);
      return (
        <>
          <NumberInput
            key={index}
            name={"size " + size.linked_product_sku.slice(lengthToCut)}
            label={getEuSizeLabel(size, lengthToCut, type)}
            placeholder="0"
            value={sizes["size " + size.linked_product_sku.slice(lengthToCut)]}
            onChange={onChange}
            error={errors.sizes}
          />
        </>
      );
    });
  }

  function insoleSizing() {
    return currency === "$" ? (
      <>
        <NumberInput
          name={"size 3-4"}
          label={"US 5.5-7"}
          placeholder="0"
          value={sizes["size 3-4"]}
          onChange={onChange}
          error={errors.sizes}
        />
        <NumberInput
          name={"size 5-6"}
          label={"US 7.5-9"}
          placeholder="0"
          value={sizes["size 5-6"]}
          onChange={onChange}
          error={errors.sizes}
        />
        <NumberInput
          name={"size 7-8"}
          label={"US 9.5-11"}
          placeholder="0"
          value={sizes["size 7-8"]}
          onChange={onChange}
          error={errors.sizes}
        />
        <NumberInput
          name={"size 9-10"}
          label={"US 11.5-13"}
          placeholder="0"
          value={sizes["size 9-10"]}
          onChange={onChange}
          error={errors.sizes}
        />
        <NumberInput
          name={"size 11-12"}
          label={"US 11.5-13"}
          placeholder="0"
          value={sizes["size 11-12"]}
          onChange={onChange}
          error={errors.sizes}
        />
      </>
    ) : (
      <>
        <NumberInput
          name={"size 3-4"}
          label={"UK 3-4"}
          placeholder="0"
          value={sizes["size 3-4"]}
          onChange={onChange}
          error={errors.sizes}
        />
        <NumberInput
          name={"size 5-6"}
          label={"UK 5-6"}
          placeholder="0"
          value={sizes["size 5-6"]}
          onChange={onChange}
          error={errors.sizes}
        />
        <NumberInput
          name={"size 7-8"}
          label={"UK 7-8"}
          placeholder="0"
          value={sizes["size 7-8"]}
          onChange={onChange}
          error={errors.sizes}
        />
        <NumberInput
          name={"size 9-10"}
          label={"UK 9-10"}
          placeholder="0"
          value={sizes["size 9-10"]}
          onChange={onChange}
          error={errors.sizes}
        />
        <NumberInput
          name={"size 11-12"}
          label={"UK 11-12"}
          placeholder="0"
          value={sizes["size 11-12"]}
          onChange={onChange}
          error={errors.sizes}
        />
      </>
    );
  }

  function getLengthToCut(size) {
    if (currency === "$") {
      if (size.linked_product_sku.includes(".")) {
        return -3;
      }
      if (size.linked_product_sku.split("-")[1] > 9) {
        return -2;
      }
      return -1;
    } else {
      if (size.linked_product_sku.includes(".")) {
        return -3;
      }
      if (size.linked_product_sku.length > 8) {
        return -2;
      }
      return -1;
    }
  }
  function getSizeLabelWomen(size, lengthToCut, type) {
    console.log("type women", type);
    if (currency === "$") {
      return (
        "EU " +
        getEuSizeLabelWomen(
          parseFloat(size.linked_product_sku.slice(lengthToCut)) + 2
        )
        // +
        // " " +
        // (parseFloat(size.linked_product_sku.slice(lengthToCut)) + 2)
      );
    } else {
      return "UK " + size.linked_product_sku.slice(lengthToCut);
    }
  }
  function getSizeLabelMen(size, lengthToCut, type) {
    console.log("type men :", type);
    if (currency === "$") {
      // if (type && type.toLowerCase() === "shoe") {
      return (
        "EU " +
        getEuSizeLabelMen(
          parseFloat(size.linked_product_sku.slice(lengthToCut)) + 1
        )
        //  +
        // " " +
        // (parseFloat(size.linked_product_sku.slice(lengthToCut)) + 2)
      );
      // } else {
      //   return (
      //     "EU " +
      //     getEuSizeLabelMen(
      //       parseInt(size.linked_product_sku.slice(lengthToCut)) + 2
      //     )
      //     // ".5-" +
      //     // getEuSizeLabelMen(
      //     //   parseInt(size.linked_product_sku.slice(lengthToCut)) + 3
      //     // )
      //   );
      // }
    } else {
      return "UK " + size.linked_product_sku.slice(lengthToCut);
    }
  }
  function getSizeLabel(size, lengthToCut, type) {
    console.log("type", type);
    if (currency === "$") {
      if (type && type.toLowerCase() === "shoe") {
        return (
          "EU " +
          getEuSizeLabelWomen(
            parseFloat(size.linked_product_sku.slice(lengthToCut)) + 2
          )
          // +
          // " " +
          // (parseFloat(size.linked_product_sku.slice(lengthToCut)) + 2)
        );
      } else {
        return (
          "EU " +
          getEuSizeLabelMen(
            parseInt(size.linked_product_sku.slice(lengthToCut)) + 2
          )
          // +
          // ".5-" +
          // getEuSizeLabelMen(
          //   parseInt(size.linked_product_sku.slice(lengthToCut)) + 3
          // )
        );
      }
    } else {
      return "UK " + size.linked_product_sku.slice(lengthToCut);
    }
  }

  function getEuSizeLabelWomen(size) {
    // let extractedSize = parseFloat(size.linked_product_sku.slice(lengthToCut));
    let extractedSize = size;
    let formattedSize = null;
    switch (parseFloat(extractedSize)) {
      case 4:
        formattedSize = 35;
        break;
      case 5:
        formattedSize = 36;
        break;
      case 6:
        formattedSize = 37;
        break;
      case 6.5:
        formattedSize = 37.5;
        break;
      case 7:
        formattedSize = 38;
        break;
      case 7.5:
        formattedSize = 38.5;
        break;
      case 8:
        formattedSize = 39;
        break;
      case 8.5:
        formattedSize = 40;
        break;
      case 9:
        formattedSize = 41;
        break;
      case 10:
        formattedSize = 42;
        break;
      case 11:
        formattedSize = 43;
        break;
      default:
        formattedSize = null;
    }
    return formattedSize;
  }
  function getEuSizeLabelMen(size) {
    // let extractedSize = parseFloat(size.linked_product_sku.slice(lengthToCut));
    let extractedSize = size;
    let formattedSize = null;
    switch (parseFloat(extractedSize)) {
      case 7:
        formattedSize = 39;
        break;
      case 7.5:
        formattedSize = 40;
        break;
      case 8:
        formattedSize = 41;
        break;
      case 9:
        formattedSize = 42;
        break;
      case 10:
        formattedSize = 43;
        break;
      case 11:
        formattedSize = 44;
        break;
      case 12:
        formattedSize = 45;
        break;
      default:
        formattedSize = null;
    }
    return formattedSize;
  }
  function getEuSizeLabel(size, lengthToCut) {
    let extractedSize = parseFloat(size.linked_product_sku.slice(lengthToCut));
    let formattedSize = null;
    switch (parseFloat(extractedSize)) {
      case 2:
        formattedSize = 35;
        break;
      case 3:
        formattedSize = 36;
        break;
      case 4:
        formattedSize = 37;
        break;
      case 4.5:
        formattedSize = 37.5;
        break;
      case 5:
        formattedSize = 38;
        break;
      case 5.5:
        formattedSize = 38.5;
        break;
      case 6:
        formattedSize = 39;
        break;
      case 6.5:
        formattedSize = 40;
        break;
      case 7:
        formattedSize = 41;
        break;
      case 8:
        formattedSize = 42;
        break;
      case 9:
        formattedSize = 43;
        break;
      case 10:
        formattedSize = 44;
        break;
      case 11:
        formattedSize = 45;
        break;
      default:
        formattedSize = null;
    }
    return "EU " + formattedSize;
  }

  function getFootwearType(style) {
    let type = style.custom_attributes.find((obj) => {
      return obj.attribute_code === "footwear_type";
    });
    if (type && type.value) {
      return type.value.toLowerCase();
    }
  }
};

export default Sizes;
