import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";

import Home from "../../app/home/Index.js";
import Style from "../../app/style/Index.js";
import Styles from "../../app/styles/Index.js";
import Report from "../../app/report/Index.js";
import Success from "../checkout/success/Index.js";
import Confirm from "../checkout/confirm/Index.js";
import Login from "../auth/LoginPage.js";
import CustomerPage from "../../app/customer/Index.js";
import AccountPage from "../auth/AccountPage.js";
import Orders from "../../app/orders/Index.js";
import Offline from "../../app/offline/Index.js";
import Order from "../../app/order/Index.js";
import Stock from "../../app/stock/Index.js";
import EditOrder from "../../app/order/Edit.js";
import adminLogin from "../admin/auth/AdminLoginPage.js";
import adminHome from "../admin/home/index.js";
import adminOrders from "../admin/orders/index.js";
import adminReports from "../admin/reports/index.js";
import adminBuildReport from "../admin/report/index.js";
import reportForm from "../admin/reports/ReportForm.js";
import orderDetail from "../admin/reports/OrderDetail.js";
import AdminOrderView from "../../app/admin/orders/View.js";
import OrderPlanner from "../../app/admin/planner/index.js";
import CreatePlanner from "../../app/admin/planner/Create.js";
import ViewPlanner from "../../app/admin/planner/View.js";
import EditPlanner from "../../app/admin/planner/edit.js";

import Returns from "../../app/returns/Index.js";

//sales rep
import repReports from "../reportBuilder/reports/index.js";
import repBuildReport from "../reportBuilder/report/index.js";
import repReportForm from "../reportBuilder/reports/ReportForm.js";

import PageNotFound from "../../app/notFound/Index.js";
import ProtectedRoute from "../auth/ProtectedRoute.js";
import AdminProtectedRoute from "../admin/auth/AdminProtectedRoute.js";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import OrderReturn from "../../app/returns/Order/Index.js";
import ReturnsList from "../../app/returns/List.js";
import ReturnsSuccess from "../../app/returns/confirm/index.js";

import "./Routes.css";

function Container({ location }) {
  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        timeout={{ enter: 300, exit: 300 }}
        classNames="fade"
      >
        <Switch location={location}>
          <ProtectedRoute exact path="/" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/admin" component={adminLogin} />
          <ProtectedRoute path="/customer" component={CustomerPage} />
          <ProtectedRoute path="/styles" component={Styles} />
          <ProtectedRoute path="/style/:slug" component={Style} />
          <ProtectedRoute path="/checkout" component={Confirm} />
          <ProtectedRoute path="/orders" component={Orders} />
          <ProtectedRoute path="/draftorders/:is_draft" component={Orders} />
          <ProtectedRoute path="/offline" component={Offline} />
          <ProtectedRoute path="/order/:slug" component={Order} />
          <ProtectedRoute path="/edit/:slug" component={EditOrder} />
          <ProtectedRoute path="/report" component={Report} />
          <ProtectedRoute path="/stock" component={Stock} />
          <ProtectedRoute path="/success" component={Success} />
          <ProtectedRoute path="/account" component={AccountPage} />
          <ProtectedRoute path="/reps/report/:Id" component={repReportForm} />

          <ProtectedRoute path="/reps/buildreport" component={repBuildReport} />
          <ProtectedRoute path="/reps/reports" component={repReports} />
          <AdminProtectedRoute path="/admins/home" component={adminHome} />
          <AdminProtectedRoute path="/admins/orders" component={adminOrders} />
          <AdminProtectedRoute path="/admins/styles" component={Styles} />
          <AdminProtectedRoute path="/admins/style/:slug" component={Style} />
          <AdminProtectedRoute path="/admins/checkout" component={Confirm} />
          <AdminProtectedRoute path="/admins/success" component={Success} />
          <AdminProtectedRoute
            path="/admins/report/:Id"
            component={reportForm}
          />
          <AdminProtectedRoute
            path="/admins/orderdetail"
            component={orderDetail}
          />
          <AdminProtectedRoute
            path="/admins/customer"
            component={CustomerPage}
          />
          <AdminProtectedRoute
            path="/admins/buildreport"
            component={adminBuildReport}
          />
          <AdminProtectedRoute
            path="/admins/reports"
            component={adminReports}
          />
          <AdminProtectedRoute
            path="/admins/order/:slug"
            component={AdminOrderView}
          />
          <AdminProtectedRoute
            path="/admins/orderplanner"
            component={OrderPlanner}
          />
          <AdminProtectedRoute
            path="/admins/createplanner"
            component={CreatePlanner}
          />
          <AdminProtectedRoute
            path="/admins/planner/:Id"
            component={ViewPlanner}
          />
          <AdminProtectedRoute
            path="/admins/plan/edit/:Id"
            component={EditPlanner}
          />

          <ProtectedRoute path="/returns" component={Returns} />
          <ProtectedRoute path="/return/:orderId" component={OrderReturn} />
          <ProtectedRoute path="/getReturns" component={ReturnsList} />
          <ProtectedRoute
            path="/ret/success/:Id"
            component={ReturnsSuccess}
            exact
          />
          <Route component={PageNotFound} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default withRouter(Container);
