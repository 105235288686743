import React, { useEffect } from "react";
import "./Index.css";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import CustomerDetails from "../customer/CustomerDetails";
import CartList from "../checkout/CartList";
import Total from "../checkout/Total";
import { loadStyles } from "../../redux/actions/styleActions";
import Spinner from "../shared/Spinner";
import Version from "./Version";

const categoryId = process.env.REACT_APP_MAIN_CATEGORY;

function Index({
  order,
  amendedOrder,
  latestAmendedOrder,
  orders,
  styles,
  loadStyles,
  auth,
}) {
  let cart;

  useEffect(() => {
    if (styles.length === 0) {
      //hard coded to select the master category, not ideal
      loadStyles(categoryId, auth).catch((error) => {
        console.log("Loading styles failed" + error);
      });
    }
  }, []);

  const getCustomer = (currentOrder) => {
    let customer = null;
    if (currentOrder && currentOrder.length > 0 && currentOrder[0].CustomerId) {
      customer = {
        account: currentOrder[0].CustomerId,
        storeName: currentOrder[0].Store,
        contactNameFirst: currentOrder[0].Forename,
        contactNameLast: currentOrder[0].Surname,
        email: currentOrder[0].Email,
        phone: currentOrder[0].Tel,
      };
    }
    return customer;
  };

  const getAddress = (currentOrder) => {
    let addresses = [];
    for (let i = 0; i < currentOrder.length; i++) {
      if (
        (currentOrder[i].Street1 && currentOrder[i].Street2,
        currentOrder[i].Town,
        currentOrder[i].County,
        currentOrder[i].Postcode,
        currentOrder[i].Country)
      ) {
        let address = {
          address1: currentOrder[i].Street1,
          address2: currentOrder[i].Street2,
          town: currentOrder[i].Town,
          county: currentOrder[i].County,
          postcode: currentOrder[i].Postcode,
          country: currentOrder[i].Country,
        };

        if (
          !addresses.filter((value) => value.postcode === address.postcode)
            .length > 0
        ) {
          addresses.push(address);
        }
      }
    }
    return addresses;
  };

  function getPrice(price) {
    return price && price.length > 1 ? price[0] : price;
  }

  const getCart = (currentOrder, amended) => {
    if (currentOrder && currentOrder.length > 0) {
      let item = [];
      for (let i = 0; i < currentOrder.length; i++) {
        if (currentOrder[i].Qty && currentOrder[i].Price) {
          item = {
            style: currentOrder[i].Style,
            sku: currentOrder[i].Sku,
            colour: currentOrder[i].Colour,
            season: currentOrder[i].Season,
            date: currentOrder[i].RequiredDate,
            total: amended ? currentOrder[i].Qty[2] : currentOrder[i].Qty[1],
            price: getPrice(
              amended ? currentOrder[i].Price[2] : currentOrder[i].Price[1]
            ),
            address: {
              address1: currentOrder[i].Street1,
              address2: currentOrder[i].Street2,
              town: currentOrder[i].Town,
              county: currentOrder[i].County,
              postcode: currentOrder[i].Postcode,
              country: currentOrder[i].Country,
            },
            qty: {
              UK2: currentOrder[i].UK2,
              UK3: currentOrder[i].UK3,
              UK4: currentOrder[i].UK4,
              UK4half: currentOrder[i].UK4half,
              UK5: currentOrder[i].UK5,
              UK5half: currentOrder[i].UK5half,
              UK6: currentOrder[i].UK6,
              UK6half: currentOrder[i].UK6half,
              UK7: currentOrder[i].UK7,
              UK8: currentOrder[i].UK8,
              UK9: currentOrder[i].UK9,
              UK10: currentOrder[i].UK10,
              UK11: currentOrder[i].UK11,
              UK12: currentOrder[i].UK12,
              UK34: currentOrder[i].UK34,
              UK56: currentOrder[i].UK56,
              UK78: currentOrder[i].UK78,
              UK910: currentOrder[i].UK910,
              UK1112: currentOrder[i].UK1112,
            },
            type: currentOrder[i].Type,
          };
          if (cart && cart.length > 0) {
            cart.push(item);
          } else {
            cart = [item];
          }
        }
      }
      return cart;
    }
  };

  const getCurrency = (currentOrder) => {
    let currency = null;
    if (currentOrder && currentOrder.length > 0 && currentOrder[0].Currency) {
      switch (currentOrder[0].Currency) {
        case "GBP":
          currency = "£";
          break;
        case "EUR":
          currency = "€";
          break;
        case "USD":
          currency = "$";
          break;
        default:
          currency = "£";
          break;
      }
    }
    return currency;
  };

  const getTotals = (currentOrder) => {
    let totals = null;
    if (
      currentOrder &&
      currentOrder.length > 0 &&
      currentOrder[0].Qty &&
      currentOrder[0].Price
    ) {
      totals = {
        qty:
          currentOrder[0].Qty.length > 1
            ? currentOrder[0].Qty[0]
            : currentOrder[0].Qty,
        price: currentOrder[0].Price[0],
      };
    }
    return totals;
  };

  const handleGetStyle = (id) => {
    for (let style in styles) {
      if (styles.hasOwnProperty(style)) {
        if (parseInt(styles[style].id) === parseInt(id)) {
          return styles[style];
        }
      }
    }
  };

  const getStatus = (order) => {
    if (order && order.length > 0) {
      if (order[0].Cancelled) {
        return <h2 class="order-notice cancelled">Cancelled</h2>;
      } else if (order[0].Imported) {
        return <h2 class="order-notice imported">Imported</h2>;
      } else if (order[0].Confirmed) {
        return <h2 class="order-notice confirmed">Confirmed</h2>;
      } else if (order[0].Amended) {
        return <h2 class="order-notice amended">Amended</h2>;
      }
    }
  };
  const getDiscount = () => {
    console.log(order);
    if (order[0].SpecialDiscount) {
      return order[0].SpecialDiscount + "% off initial order";
      // if (order[0].SpecialDiscount > 5) {
      //   return order[0].SpecialDiscount + " Program Offer";
      // } else {
      //   return order[0].SpecialDiscount + "% off initial order";
      // }
    }
  };

  return !orders || orders.length === 0 ? (
    <Redirect to="/orders" />
  ) : styles && styles.length === 0 ? (
    <Spinner />
  ) : (
    <div className="content order">
      <h1>
        {order && order.length > 0 && order[0].CustomerId
          ? "ORDER FOR " + order[0].CustomerId
          : null}{" "}
        <span>
          {order && order.length > 0 && order[0].Amended ? "(Amended)" : null}
        </span>
      </h1>
      {getStatus(order)}
      <Version
        order={order}
        orderAmends={amendedOrder}
        currency={getCurrency(order)}
      ></Version>
      <CustomerDetails
        customer={getCustomer(order)}
        reOpenCustomerForm={false}
        customerFormOpen={false}
        confirm={"null"}
      />
      <div className="confirm-styles">
        <h3 className="pre-btn">Styles</h3>
        <CartList
          cart={getCart(
            latestAmendedOrder && latestAmendedOrder.length > 0
              ? latestAmendedOrder
              : order,
            latestAmendedOrder && latestAmendedOrder.length > 0 ? true : false
          )}
          totals={getTotals(
            latestAmendedOrder && latestAmendedOrder.length > 0
              ? latestAmendedOrder
              : order
          )}
          currency={getCurrency(order)}
          addresses={getAddress(order)}
          getStyle={handleGetStyle}
        />
      </div>
      <Total
        totals={getTotals(
          latestAmendedOrder && latestAmendedOrder.length > 0
            ? latestAmendedOrder
            : order
        )}
        currency={getCurrency(order)}
      />
      {order[0].Comments ? (
        <div>
          <h3 className="pre-btn">Comments</h3>
          <p>{order[0].Comments}</p>
          <p>
            {latestAmendedOrder && latestAmendedOrder.length > 0
              ? latestAmendedOrder[0].Comments
              : null}
          </p>
        </div>
      ) : null}
      {order[0].SpecialDiscount ? (
        <div>
          <h3 className="pre-btn">Special Discount</h3>
          <div>
            <p>{getDiscount()}</p>
          </div>
        </div>
      ) : null}
      {order[0].Promo ? (
        <div>
          <h3 className="pre-btn">Promo</h3>
          <div>
            <p>{order[0].Promo}</p>
          </div>
        </div>
      ) : null}
      {order[0].Po ? (
        <div>
          <h3 className="pre-btn">Purchase Order</h3>
          <div>
            <p>{order[0].Po}</p>
          </div>
        </div>
      ) : null}
      {order[0].Shipping ? (
        <div>
          <h3 className="pre-btn">Shipping</h3>
          <div>
            <p>{order[0].Shipping}</p>
          </div>
        </div>
      ) : null}
      <div>
        <h3 className="pre-btn">Signature</h3>
        <div>
          <img
            src={order[0].Signature}
            alt="signature of person who placed the order"
          />
        </div>
      </div>
    </div>
  );
}

function getOrderBySlug(orders, slug) {
  let order =
    orders.filter((order) => {
      return parseInt(order.Id) === parseInt(slug);
    }) || null;

  console.log("order", order);

  return order;
}

function getOrderAmendsBySlug(orders, slug) {
  let amendedOrders =
    orders.filter((order) => {
      return parseInt(order.OriginalId) === parseInt(slug);
    }) || null;

  return amendedOrders;
}

function getMostRecentOrderAmendBySlug(orders, slug) {
  let amendedOrders =
    orders.filter((order) => {
      return parseInt(order.OriginalId) === parseInt(slug);
    }) || null;

  let amendedOrder =
    amendedOrders.filter((order) => {
      return (
        parseInt(order.Id) ===
        parseInt(amendedOrders[amendedOrders.length - 1].Id)
      );
    }) || null;

  return amendedOrder;
}

function mapStateToProps(state, ownProps) {
  const slug = ownProps.match.params.slug;
  console.log("state.orders", state.orders);
  const order =
    slug && state.orders.length > 0
      ? getOrderBySlug(state.orders, slug)
      : false;

  const amendedOrder =
    slug && state.orderAmends.length > 0
      ? getOrderAmendsBySlug(state.orderAmends, slug)
      : false;

  const latestAmendedOrder =
    slug && state.orderAmends.length > 0
      ? getMostRecentOrderAmendBySlug(state.orderAmends, slug)
      : false;

  return {
    order,
    amendedOrder,
    latestAmendedOrder,
    orders: state.orders,
    styles: state.styles,
    auth: state.auth,
  };
}

const mapDispatchToProps = {
  loadStyles,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
