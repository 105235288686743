import React, { useEffect } from "react";
import SelectInput from "../shared/SelectInput";

const Dates = ({ onChange, errors, date, style ,season, seasons }) => {
  const getDateOptions = ()=>{
    const defaultOptions = [
      { value: "ASAP", text: "ASAP" },
      { value: "MARCH", text: "MARCH" },
      { value: "APRIL", text: "APRIL" },
      { value: "MAY", text: "MAY" },
      { value: "JUNE", text: "JUNE" },
      { value: "JULY", text: "JULY" },
      { value: "AUGUST", text: "AUGUST" },
      { value: "SEPTEMBER", text: "SEPTEMBER" },
      { value: "OCTOBER", text: "OCTOBER" },
      { value: "NOVEMBER", text: "NOVEMBER" },
      { value: "DECEMBER", text: "DECEMBER" },
    ];
     console.log('season',season);
     console.log('all season',seasons);

          let available_dates = style.custom_attributes.find((obj) => {
            return obj.attribute_code === "available_dates";
          });
          console.log('available_dates',available_dates);
     if(available_dates){
          let seasonWithDates = available_dates.value.split('|');
          let dates = [];
          seasonWithDates.map((item)=>{
            let tempArr = item.split(':');
            const currseason = tempArr[0];
            const currdates = tempArr[1];
            console.log('currseason',currseason);
            const seasonObject = seasons.find((seas)=>seas.name.toLowerCase() === currseason.toLowerCase());
            console.log('seasonObject',seasonObject);
            if(seasonObject && parseInt(seasonObject.id) == season){
              let tempDateArr = currdates.split(',');
              console.log('tempDateArr',tempDateArr);
              if(tempDateArr[0] != 'All'){
                tempDateArr.map((dateItem)=>{
                  let date = { value: dateItem, text: dateItem };
                  dates.push(date);
                })
              }else{
                  dates = defaultOptions;
              }
            }
            // console.log('seasonObject',seasonObject);
        });
        console.log('dates',dates);
        return dates;
     }

     return defaultOptions;
  }

  return (
    <div className="select-delivery">
      <h3>Date</h3>
      <SelectInput
        name="date"
        onChange={onChange}
        label={"date"}
        defaultOption="Select Date"
        value={date}
        error={errors.date}
        options={getDateOptions()}
      />
    </div>
  );
};

export default Dates;
