import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadStyles } from "../../redux/actions/styleActions";
import { loadOptions } from "../../redux/actions/optionActions";
import { addToCart } from "../../redux/actions/cartActions";
import { updateTotals } from "../../redux/actions/totalActions";
import Style from "./Style";
import Spinner from "../../app/shared/Spinner";
import { toast } from "react-toastify";
import { Redirect, NavLink } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import "./Index.css";

function Index({
  styles,
  currency,
  addresses,
  billingAddresses,
  shippingAddresses,
  customer,
  options,
  loadStyles,
  loadOptions,
  history,
  addToCart,
  updateTotals,
  auth,
  currentSeason,
  cart,
  seasons,
  ...props
}) {
  const [style, setStyle] = useState({});
  const [colour, setColour] = useState(null);
  const [date, setDate] = useState("ASAP");
  const [address, setAddress] = useState(0);
  const [optionSet, setOptions] = useState([]);
  const [sizes, setSizes] = useState({
    "size 2": null,
    "size 3": null,
    "size 4": null,
    "size 4.5": null,
    "size 5": null,
    "size 5.5": null,
    "size 6": null,
    "size 6.5": null,
    "size 7": null,
    "size 7.5": null,
    "size 8": null,
    "size 9": null,
    "size 10": null,
    "size 11": null,
    "size 12": null,
    "size 3-4": null,
    "size 5-6": null,
    "size 7-8": null,
    "size 9-10": null,
    "size 11-12": null,
  });
  const [colours, setColours] = useState([]);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState(false);
  const [deliverySeason, setDeliverySeason] = useState("Spring/Summer");

  useEffect(() => {
    if (!address || address === null) {
      if (cart.length > 0 && cart[0].address) {
        console.log("cart address", cart[0].address);
        setAddress(cart[0].address);
      }
      if (cart.length > 0 && cart[0].address) {
        let cartAddress = cart[0].address;
        let cartAddresslabel = concatAddress(cartAddress);
        let selectedAddress = { value: "0", text: "" };
        shippingAddresses.map((address, index) => {
          let addressLabel = concatAddress(address);
          if (addressLabel == cartAddresslabel) {
            selectedAddress = {
              value: index,
              text: addressLabel,
            };
            console.log("selectedAddress 1", selectedAddress);
          }
        });
        console.log("selectedAddress main", selectedAddress);
        console.log(
          "selectedAddress value type",
          typeof selectedAddress.value.toString()
        );

        setAddress(selectedAddress.value.toString());
      }
    }

    function concatAddress(address) {
      let addressLabel =
        address.address1 +
        ", " +
        address.address2 +
        ", " +
        address.town +
        ", " +
        address.county +
        ", " +
        address.postcode +
        ", " +
        address.country;
      return addressLabel;
    }

    if (styles.length === 0) {
      loadStyles(auth).catch((error) => {
        setApiError(true);
        console.log("Loading styles failed" + error);
      });
    } else {
      setStyle({ ...props.style });
      setColours({ ...props.colours });
    }
    if (!options || !options.colour || !options.season) {
      if (!options.colour) {
        loadOptions("group_colour", auth, "colour").catch((error) => {
          setApiError(true);
          console.log("Loading colour options failed" + error);
        });
      }
      if (!options.season) {
        loadOptions("season", auth, "season").catch((error) => {
          setApiError(true);
          console.log("Loading season options failed" + error);
        });
      }
    } else {
      setOptions({ ...props.options });
    }
  }, [props.style, props.colours]);

  function handleChange(event) {
    const { name, value } = event.target;
    if (name.indexOf("size") !== -1) {
      if (value === "") {
        setSizes((prevSize) => ({
          ...prevSize,
          [name]: "0",
        }));
        return;
      }
      setSizes((prevSize) => ({
        ...prevSize,
        [name]: parseInt(value),
      }));
    } else if (name.indexOf("colour") !== -1) {
      setColour(value);
    } else if (name.indexOf("address") !== -1) {
      setAddress(value);
    } else if (name.indexOf("date") !== -1) {
      setDate(value);
    } else if (name.indexOf("deliverySeason") !== -1) {
      setDeliverySeason(value);
    }
  }

  function getProductType() {
    if (style && style.custom_attributes) {
      let optionValue = style.custom_attributes.find((obj) => {
        return obj.attribute_code === "footwear_type";
      });

      if (optionValue && optionValue.value) {
        return optionValue.value;
      }
    }
  }

  function formIsValid() {
    const errors = {};
    let productType = getProductType();
    if (productType !== "insole") {
      if (!colour || colour === null)
        errors.colour = "You have not selected a colour";
    }
    if (!address || address === null) {
      errors.address = "You have not selected a delivery address";
    }
    if (!date || date === null) errors.date = "You have not selected a date";
    if (
      !sizes ||
      (sizes["size 2"] === null &&
        sizes["size 3"] === null &&
        sizes["size 4"] === null &&
        sizes["size 5"] === null &&
        sizes["size 5.5"] === null &&
        sizes["size 6"] === null &&
        sizes["size 6.5"] === null &&
        sizes["size 7"] === null &&
        sizes["size 8"] === null &&
        sizes["size 9"] === null &&
        sizes["size 10"] === null &&
        sizes["size 11"] === null &&
        sizes["size 12"] === null &&
        sizes["size 3-4"] === null &&
        sizes["size 5-6"] === null &&
        sizes["size 7-8"] === null &&
        sizes["size 9-10"] === null &&
        sizes["size 11-12"] === null)
    )
      errors.sizes = "You have not selected any sizes";
    setErrors(errors);
    // Form is valid if the errors object still has no properties
    return Object.keys(errors).length === 0;
  }

  const handleCheckout = () => {
    if (cart && cart.length === 0) {
      if (!formIsValid()) return;
      addProductToCart();
    }
    history.push("/checkout");
  };

  const handleAddToCart = (event) => {
    event.preventDefault();
    if (!formIsValid()) return;
    addProductToCart();
  };
  function addProductToCart() {
    setSaving(true);
    try {
      let qty = getQty();
      let currentStyle = props.style;
      let price = getPrice(qty, currentStyle);
      let colourLabel = getColourLabel(colour);
      let type = getType(colour);
      let season = getSeason(colour);
      let selectedDeliverySeason = deliverySeason;

      //need to be able to differentiate between seasons better
      if (parseInt(currentSeason) == 49 || parseInt(currentSeason) == 81) {
        selectedDeliverySeason = "Autumn/Winter";
      } else if (
        parseInt(currentSeason) == 70 ||
        parseInt(currentSeason) == 48
      ) {
        selectedDeliverySeason = "Spring/Summer";
      }

      addToCart({
        style: currentStyle.name,
        sku: colour.startsWith("USR")
          ? colour.replace(/^USR+/i, "")
          : colour.replace(/^R+/i, ""),
        colour: colourLabel,
        season: currentSeason,
        deliverySeason: selectedDeliverySeason,
        type: type,
        qty: {
          UK2: sizes["size 2"],
          UK3: sizes["size 3"],
          UK4: sizes["size 4"],
          UK4half: sizes["size 4.5"],
          UK5: sizes["size 5"],
          UK5half: sizes["size 5.5"],
          UK6: sizes["size 6"],
          UK6half: sizes["size 6.5"],
          UK7: sizes["size 7"],
          UK7half: sizes["size 7.5"],
          UK8: sizes["size 8"],
          UK9: sizes["size 9"],
          UK10: sizes["size 10"],
          UK11: sizes["size 11"],
          UK12: sizes["size 12"],
          UK34: sizes["size 3-4"],
          UK56: sizes["size 5-6"],
          UK78: sizes["size 7-8"],
          UK910: sizes["size 9-10"],
          UK1112: sizes["size 11-12"],
        },
        // address: addresses[address],
        address: shippingAddresses[address],
        date: date,
        total: qty,
        price: price,
      });

      updateTotals({ qty, price });

      toast.success("Added to Cart", { autoClose: true });

      setSaving(false);
    } catch (error) {
      setSaving(false);
      setErrors({ onSave: error.message });
    }
  }

  function getColourLabel(colour) {
    let style = styles.find((style) => style.sku === colour) || null;

    if (style && style.custom_attributes) {
      let optionValue = style.custom_attributes.find((obj) => {
        return obj.attribute_code === "group_colour";
      });

      if (optionValue && optionValue.value) {
        let optionLabel = getOptionLabel("group_colour", optionValue.value);

        return optionLabel.toString().split(",")[0];
      }
    }
  }

  function getType(colour) {
    console.log("addto cart colour", colour);
    let style = styles.find((style) => style.sku === colour) || null;
    console.log("addto cart style", style);

    if (style && style.custom_attributes) {
      let optionValue = style.custom_attributes.find((obj) => {
        return obj.attribute_code === "footwear_type";
      });
      console.log("addto cart footwear_type", optionValue);

      if (optionValue && optionValue.value) {
        return optionValue.value;
      }
    }
  }

  function getSeason(colour) {
    let style = styles.find((style) => style.sku === colour) || null;

    if (style && style.custom_attributes) {
      let optionValue = style.custom_attributes.find((obj) => {
        return obj.attribute_code === "season";
      });

      if (optionValue && optionValue.value) {
        return optionValue.value;
      }
    }
  }

  function getOptionLabel(optionId, option) {
    if (options) {
      if (optionId === "group_colour") {
        if (options.colour) {
          let result = options.colour.find((obj) => {
            return obj.value === option;
          });
          if (result && result.label) {
            return result.label;
          }
        }
      }
      if (optionId === "season") {
        if (options.season) {
          let result = options.season.find((obj) => {
            return obj.value === option;
          });
          if (result && result.label) {
            return result.label;
          }
        }
      }
    }
    return false;
  }

  function getPrice(qty, currentStyle) {
    let price = 0;
    if (currentStyle) {
      let productPrice = currentStyle.custom_attributes.find((obj) => {
        return obj.attribute_code === "group_price";
      });
      price = productPrice.value * qty;
    }
    return Math.round((price + Number.EPSILON) * 100) / 100;
  }

  function getQty() {
    let total = 0;

    for (let size in sizes) {
      if (sizes[size]) {
        total = parseInt(total) + parseInt(sizes[size]);
      }
    }

    return total;
  }

  if (!customer || Object.keys(customer).length === 0) {
    return <Redirect to="/" />;
  }

  return styles.length === 0 && !apiError ? (
    <Spinner />
  ) : (
    <div class="content style-page">
      <NavLink class="btn btn-primary btn-link-custom" to="/styles">
        <BsChevronLeft /> Back
      </NavLink>
      <Style
        style={props.style}
        getOptionLabel={getOptionLabel}
        currency={currency}
        date={date}
        colours={props.colours}
        // addresses={addresses}
        addresses={shippingAddresses}
        address={address}
        sizes={sizes}
        cart={cart}
        onChange={handleChange}
        onSave={handleAddToCart}
        errors={errors}
        saving={saving}
        deliverySeason={deliverySeason}
        season={getSeason(props.style.sku)}
        currentSeason={currentSeason}
        setColour={setColour}
        handleCheckout={handleCheckout}
        seasons={seasons}
      />
    </div>
  );
}

export function getStyleBySlug(styles, slug) {
  return styles.find((style) => style.name === slug) || null;
}

export function getColoursForStyle(styles, style, currentSeason) {
  let family = style.custom_attributes.find((obj) => {
    return obj.attribute_code === "family";
  });

  return styles.filter((style) => {
    let optionValue = style.custom_attributes.find((obj) => {
      return obj.attribute_code === "family";
    });

    let categories = style.extension_attributes.category_links;
    let isInCategory = false;

    for (let i = 0; i < categories.length; i++) {
      if (parseInt(categories[i].category_id) === parseInt(currentSeason)) {
        isInCategory = true;
      }
    }

    if (optionValue && optionValue.value && family && family.value) {
      let familyMatch = optionValue.value === family.value;
      return familyMatch && isInCategory;
    }
  });
}

function mapStateToProps(state, ownProps) {
  const slug = ownProps.match.params.slug;

  const style =
    slug && state.styles.length > 0
      ? getStyleBySlug(state.styles, slug)
      : false;

  const colours =
    state.styles.length > 0 && style
      ? getColoursForStyle(state.styles, style, state.currentSeason)
      : false;

  console.log("states", state);

  return {
    styles: state.styles,
    style,
    colours,
    options: state.options,
    currency: state.currency,
    addresses: state.addresses,
    billingAddresses: state.billingAddresses,
    shippingAddresses: state.shippingAddresses,
    customer: state.customer,
    auth: state.auth,
    currentSeason: state.currentSeason,
    cart: state.cart,
    seasons: state.seasons,
  };
}

const mapDispatchToProps = {
  loadStyles,
  addToCart,
  updateTotals,
  loadOptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
