import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  emptyCart,
  removeItem,
  cancelOrder,
} from "../../../redux/actions/cartActions";
import CustomerDetails from "../../../app/customer/CustomerDetails";
import AddressDetails from "../../../app/address/AddressDetails";
import Total from "../Total";
import Declaration from "./Declaration";
import Comment from "./Comment";
import Po from "./Po";
import { NavLink, Redirect } from "react-router-dom";
import {
  submitOrder,
  submitOrderChanges,
} from "../../../redux/actions/orderActions";
import CartList from "../CartList";
import "./Index.css";
import Pos from "../Pos";
import Terms from "./Terms";
import { Offline, Online } from "react-detect-offline";
import Checkbox from "../../shared/Checkbox";
import ShippingInstructions from "./SpecialShipping";
import Discount from "./Discount";
import Promo from "./Promo";

import ImageUploader from "./Image";
import RouteLeavingGuard from "../../route/routeleavingguard";

export function Index({
  cart,
  pos,
  season,
  currency,
  customer,
  addresses,
  billingAddresses,
  shippingAddresses,
  totals,
  styles,
  history,
  submitOrder,
  submitOrderChanges,
  auth,
  adminAuth,
  options,
  user,
  amend,
  currentSeason,
  confirm,
  cancelOrder,
}) {
  const [errors, setErrors] = useState({});
  const [comments, setComments] = useState(
    confirm && confirm.comments ? confirm.comments : ""
  );
  const [po, setPo] = useState(confirm && confirm.po ? confirm.po : "");
  const [discount, setDiscount] = useState(
    confirm && confirm.discount ? confirm.discount : ""
  );
  const [shipping, setShipping] = useState(
    confirm && confirm.shipping ? confirm.shipping : ""
  );
  const [signature, setSignature] = useState(
    confirm && confirm.signature ? confirm.signature : ""
  );
  const [promo, setPromo] = useState(
    confirm && confirm.promo ? confirm.promo : ""
  );
  const [saving, setSaving] = useState(false);
  const [atOnce, setAtOnce] = useState(false);
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [cancelConfirmation, setCancelConfirmation] = useState(false);
  const [submitConfirmation, setSubmitConfirmation] = useState(false);
  const [showPrompt, setShowPrompt] = useState(true);

  useEffect(() => {
    if (customer && customer.signature) {
      setSignature({ trimmedDataURL: customer.signature });
    }
    if (customer && customer.comments) {
      setComments(customer.comments);
    }
  }, []);

  const handleClearCart = (event) => {
    event.preventDefault();
    try {
      emptyCart();
      toast.success("Cart Emptied", { autoClose: true });
    } catch (error) {
      setErrors({ onSave: error.message });
    }
  };

  const getOptionLabel = (optionId, option) => {
    if (options) {
      if (optionId === "season") {
        if (options.season) {
          let result = options.season.find((obj) => {
            return obj.value === option;
          });
          console.log("option", option);
          console.log("result", result);
          if (result && result.label) {
            return result.label;
          }
        }
      }
    }
    return false;
  };

  const handleGetStyle = (id) => {
    for (let style in styles) {
      if (styles.hasOwnProperty(style)) {
        if (styles[style].id === id) {
          return styles[style];
        }
      }
    }
  };

  const getCartForAddress = (address, type) => {
    let cartList = cart.filter(function (item) {
      return item.address.address1 === address.address1;
    });

    if (!type) {
      return cartList;
    }

    let items = cartList.filter(function (item) {
      if (item.type) {
        return item.type.toLowerCase() === type;
      }
      return false;
    });

    return items;
  };

  const submitNewOrder = (seasonLabel, is_cancel_click = 0, is_draft = 0) => {
    let at_once = 1;
    if (is_draft == 1) {
      at_once = 0;
    }
    if (adminAuth) {
      auth = adminAuth;
    }
    submitOrder(
      cart,
      customer,
      totals,
      // addresses,
      shippingAddresses,
      // billingAddresses,
      currency,
      pos,
      comments,
      po,
      shipping,
      signature,
      seasonLabel,
      auth,
      user,
      currentSeason,
      at_once,
      discount,
      defaultFileList,
      is_draft,
      promo
    )
      .then((result) => {
        if (result.status === "success") {
          cancelOrder(auth);
          if (is_draft == 1) {
            toast.success("Order Saved to 'My Draft Orders' Tab", {
              autoClose: true,
            });
          } else {
            toast.success("Order Submitted", { autoClose: true });
          }
          if (customer && customer.storageId) {
            localStorage.removeItem(customer.storageId);
          }
          // return history.replace({
          //   pathname: "/success",
          //   state: {
          //     result: result,
          //   },
          // });
          result.is_draft = is_draft;
          return history.push({
            pathname: "/success",
            state: {
              result: result,
            },
          });
        } else {
          toast.error(
            "Order could not be submitted, try again, or save it for later",
            {
              autoClose: true,
            }
          );
        }
      })
      .catch((error) => {
        toast.error(
          "Order could not be submitted, try again, or save it for later",
          {
            autoClose: true,
          }
        );
      });
  };

  const submitChanges = (originalOrderId, seasonLabel, is_draft) => {
    let at_once = 1;
    let is_admin = 0;
    if (is_draft == 1) {
      at_once = 0;
    }
    if (adminAuth) {
      auth = adminAuth;
      is_admin = 1;
    }
    // console.log("is_draft", is_draft);
    // return;
    submitOrderChanges(
      cart,
      customer,
      totals,
      currency,
      comments,
      po,
      shipping,
      auth,
      user,
      seasonLabel,
      discount,
      defaultFileList,
      originalOrderId,
      shippingAddresses,
      is_draft,
      at_once,
      promo
    )
      .then((result) => {
        console.log("result", result);
        if (result.status === "success") {
          cancelOrder(auth);
          if (is_draft == 1) {
            toast.success("Order Saved to 'My Draft Orders' Tab", {
              autoClose: true,
            });
          } else {
            toast.success("Order Submitted", { autoClose: true });
          }
          // toast.success("Order Submitted", { autoClose: true });
          result.is_draft = is_draft;
          if (is_admin) {
            return history.push({
              pathname: "/admins/success",
              state: {
                result: result,
              },
            });
          } else {
            return history.push({
              pathname: "/success",
              state: {
                result: result,
              },
            });
          }
        } else {
          toast.error(
            "Order could not be submitted, try again, or save it for later",
            {
              autoClose: true,
            }
          );
        }
      })
      .catch((error) => {
        toast.error(
          "Order could not be submitted, try again, or save it for later",
          {
            autoClose: true,
          }
        );
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let seasonLabel = season;

    if (options && options.season) {
      if (cart.length > 0 && cart[0] && cart[0].season) {
        seasonLabel = getOptionLabel("season", cart[0].season);
      }
    }

    amend
      ? submitChanges(amend, seasonLabel)
      : submitNewOrder(seasonLabel, 0, 0);
  };
  const handleSubmitBtn = () => {
    setSubmitConfirmation(true);
  };

  const handleSave = (e) => {
    e.preventDefault();

    let seasonLabel = season;

    if (options && options.season) {
      if (cart.length > 0 && cart[0] && cart[0].season) {
        seasonLabel = getOptionLabel("season", cart[0].season);
      }
    }

    let dateTime = new Date().getDate() + new Date().getTime();
    let id = customer.account + dateTime;

    localStorage.setItem(
      id,
      JSON.stringify({
        id: id,
        signature: signature,
        comments: comments,
        cart,
        customer,
        totals,
        // addresses,
        shippingAddresses,
        // billingAddresses,
        currency,
        pos,
        comments,
        po,
        signature,
        seasonLabel,
        currentSeason,
        user,
      })
    );

    toast.success("Order Saved", { autoClose: true });
  };

  const shouldBeHere = () => {
    if (!customer || Object.keys(customer).length === 0) {
      return <Redirect to="/" />;
    }

    if (
      (customer && Object.keys(customer).length > 0 && !shippingAddresses) ||
      shippingAddresses.length === 0
    ) {
      return <Redirect to="/customer" />;
    }
    return true;
  };

  function showPos() {
    return pos && pos.length > 0 ? (
      <div class="confirm-styles">
        <h3 className="pre-btn">Pos</h3>
        <Pos pos={pos} />
      </div>
    ) : null;
  }

  function handleChange(event) {
    const { value, name, checked } = event.target;
    if (name === "comments") {
      setComments(value);
    } else if (name === "atOnce") {
      setAtOnce(checked);
    } else if (name === "shipping") {
      setShipping(value);
    } else if (name === "discount") {
      var regex = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/;
      if (!regex.test(value)) {
        alert("please enter number only");
      } else {
        setDiscount(value);
      }
    } else if (name === "promo") {
      setPromo(value);
    } else {
      setPo(value);
    }
  }
  function handleSaveAsDraft() {
    setShowPrompt(false);
    let is_draft = 1;
    submitOrderfnc(is_draft);
  }
  function handleCancel() {
    setCancelConfirmation(true);
  }
  function handleCancelYes() {
    setCancelConfirmation(false);
    setShowPrompt(false);
    let is_draft = 1;
    submitOrderfnc(is_draft);
  }
  function handleSubmitYes() {
    setSubmitConfirmation(false);
    setShowPrompt(false);
    let is_draft = 0;
    setAtOnce(false);
    submitOrderfnc(is_draft);
  }
  function submitOrderfnc(is_draft = 0, is_cancel_click = 0) {
    let seasonLabel = season;

    if (options && options.season) {
      if (cart.length > 0 && cart[0] && cart[0].season) {
        seasonLabel = getOptionLabel("season", cart[0].season);
      }
    }
    // return;
    amend
      ? submitChanges(amend, seasonLabel, is_draft)
      : submitNewOrder(seasonLabel, is_cancel_click, is_draft);
  }
  function handleNo() {
    setCancelConfirmation(false);
    setSubmitConfirmation(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  function showSubmitBtn(online) {
    return amend ? (
      <>
        {/* <button class="btn  btn-primary" type="submit" disabled={online}>
          {saving ? "Submitting" : "Submit Changes"}
        </button> */}
        <a
          onClick={handleSubmitBtn}
          className="btn  btn-primary btn-link-custom"
          disabled={online}
        >
          {saving ? "Submitting" : "Submit Changes"}
        </a>
        {
          <a
            onClick={handleSaveAsDraft}
            className="btn btn-primary btn-next btn-link-custom mg-left"
          >
            Save as draft
          </a>
        }
      </>
    ) : (
      <>
        <a
          onClick={handleSubmitBtn}
          className="btn  btn-primary btn-link-custom"
          disabled={online}
        >
          {saving ? "Submitting" : "Submit Order"}
        </a>
        <a
          onClick={handleCancel}
          className="btn btn-primary btn-next btn-link-custom mg-left"
        >
          Cancel
        </a>
      </>
    );
  }

  function showSaveBtn() {
    return (
      <button class="btn  btn-primary" type="submit">
        {saving ? "Submitting" : "Save Order"}
      </button>
    );
  }

  return shouldBeHere ? (
    <div className="content confirm">
      {!amend ? (
        <RouteLeavingGuard
          when={showPrompt}
          navigate={(path) => history.push(path)}
          shouldBlockNavigation={(location) => {
            return true;
          }}
          handleCheckout={handleSubmitYes}
          handleDraft={handleCancelYes}
        />
      ) : null}

      <h1>{amend ? "Confirm Changes" : "Confirm Order"}</h1>
      <CustomerDetails
        customer={customer}
        reOpenCustomerForm={false}
        customerFormOpen={false}
        confirm={true}
      />
      <div className="confirm-customer">
        <h3 className="pre-btn">Address Book 1</h3>
        <AddressDetails
          customer={customer}
          // addresses={addresses}
          addresses={shippingAddresses}
          handleDelete={false}
          handleEdit={false}
          currency={currency}
        />
      </div>
      <div class="confirm-styles">
        <h3 className="pre-btn">Styles</h3>
        {adminAuth ? (
          <NavLink class="btn btn-secondary" to="/admins/styles">
            Edit
          </NavLink>
        ) : (
          <NavLink class="btn btn-secondary" to="/styles">
            Edit
          </NavLink>
        )}
        <CartList
          cart={cart}
          totals={totals}
          currency={currency}
          // addresses={addresses}
          addresses={shippingAddresses}
          emptyCart={handleClearCart}
          getStyle={handleGetStyle}
        />
      </div>
      {showPos()}
      <Total totals={totals} currency={currency} />
      {/* <Online> */}
      <form onSubmit={handleSubmit}>
        <Po onChange={handleChange} po={po}></Po>
        <ShippingInstructions
          onChange={handleChange}
          shipping={shipping}
        ></ShippingInstructions>
        <Discount discount={discount} onChange={handleChange} />
        <Promo promo={promo} onChange={handleChange} />
        <Comment onChange={handleChange} comments={comments}></Comment>
        <ImageUploader
          defaultFileList={defaultFileList}
          setDefaultFileList={setDefaultFileList}
          auth={auth}
        />
        <Terms amend={amend}></Terms>
        <Declaration
          setSignature={setSignature}
          signature={signature}
          amend={amend}
        />
        {/* <div className="confirm-declaration atOnce">
            <h3>Is this an at once order?</h3>
            <Checkbox
              name="atOnce"
              value={atOnce}
              label={""}
              onChange={handleChange}
              error={errors.atOnce}
            />
          </div> */}
        {showSubmitBtn(false)}
        {cancelConfirmation ? (
          <div className="mg-top-bot confirmation-dialog">
            <b>Do you want to cancel your order?</b>
            <div className="mg-top-bot">
              <a
                onClick={handleCancelYes}
                className="btn btn-link-custom c-padding"
              >
                Yes
              </a>
              <a
                onClick={handleNo}
                className="btn btn-link-custom mg-left c-padding"
              >
                No
              </a>
            </div>
          </div>
        ) : null}
        {submitConfirmation ? (
          <div className="mg-top-bot confirmation-dialog">
            <b>Do you want to submit your order?</b>
            <div className="mg-top-bot">
              <a
                onClick={handleSubmitYes}
                className="btn btn-link-custom c-padding"
              >
                Yes
              </a>
              <a
                onClick={handleNo}
                className="btn btn-link-custom mg-left c-padding"
              >
                No
              </a>
            </div>
          </div>
        ) : null}
      </form>
      {/* </Online> */}
      {/* <Offline>
        <form onSubmit={handleSave}>
          <Po onChange={handleChange} po={po}></Po>
          <ShippingInstructions
            onChange={handleChange}
            shipping={shipping}
          ></ShippingInstructions>
          <Discount discount={discount} onChange={handleChange} />
          <Comment onChange={handleChange} comments={comments}></Comment>
          <Terms amend={amend}></Terms>
          <Declaration
            setSignature={setSignature}
            signature={signature}
            amend={amend}
          />
          <p class="offline-msg">
            You are currently offline and so can only save orders to send for
            later.
          </p>
          {showSaveBtn()}
        </form>
      </Offline> */}
    </div>
  ) : null;
}

function mapStateToProps(state) {
  console.log("state.cart", state.cart);
  console.log("state.order", state.order);

  return {
    cart: state.cart,
    currency: state.currency,
    styles: state.styles,
    customer: state.customer,
    addresses: state.addresses,
    billingAddresses: state.billingAddresses,
    shippingAddresses: state.shippingAddresses,
    totals: state.totals,
    auth: state.auth,
    adminAuth: state.adminAuth,
    order: state.order,
    pos: state.pos,
    season: state.season,
    options: state.options,
    user: state.user,
    amend: state.amend,
    currentSeason: state.currentSeason,
    confirm: state.confirm,
  };
}

const mapDispatchToProps = {
  emptyCart,
  removeItem,
  submitOrder,
  submitOrderChanges,
  cancelOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
