import React, { useState, useEffect } from "react";
import { Link, StaticRouter } from "react-router-dom";
import { connect } from "react-redux";
import { cancelOrder } from "../../redux/actions/cartActions";
import Clear from "../checkout/miniCart/ClearBtn";
import { toast } from "react-toastify";
import "./Index.css";
import RequestUpdate from "../shared/Update.js";

function Home({
  cart,
  cancelOrder,
  customer,
  addresses,
  auth,
  userData,
  user,
}) {
  const [setErrors] = useState({});

  useEffect(() => {
    console.log("userData", user);
  }, []);
  const handleClearCart = (event) => {
    event.preventDefault();
    try {
      cancelOrder(auth);
      toast.success("Order Cancelled", { autoClose: true });
    } catch (error) {
      setErrors({ onSave: error.message });
    }
  };
  return (cart && cart.length > 0) ||
    (customer && Object.keys(customer).length != 1) ||
    (addresses && addresses.length) ? (
    <div className="container home">
      <div className="inner-container">
        <div className="content">
          <p className="notice">
            You were in the middle of an order for{" "}
            <span className="bold">{customer.storeName}</span>, do you want to
            carry on?
          </p>
          <div className="main-menu">
            <Link to="/customer" className="btn-new">
              Continue Order
            </Link>
            <Clear
              cart={cart}
              emptyCart={handleClearCart}
              label={"Start New"}
              addresses={addresses}
              customer={customer}
            />
          </div>
          <RequestUpdate></RequestUpdate>
        </div>
      </div>
    </div>
  ) : (
    <div className="container home">
      <div className="inner-container">
        <div className="content">
          <div className="main-menu">
            <p className="notice">Welcome back</p>
            <Link to="/customer" className="btn btn-new">
              Create a New Order
            </Link>
          </div>
          <RequestUpdate></RequestUpdate>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  console.log("state", state);
  return {
    cart: state.cart,
    customer: state.customer,
    addresses: state.addresses,
    auth: state.auth,
    userData: state.userData,
    user: state.user,
  };
}

const mapDispatchToProps = {
  cancelOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
