import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SelectInput from "../../shared/SelectInput";
import Checkbox from "../../shared/Checkbox";
import { BsPlus } from "react-icons/bs";
import { FaMinus } from "react-icons/fa";
import { getOrderDetails, getSalesReps, testcall } from "../../../api/orderApi";
import "../report/index.css";
import "./index.css";

import ReactExport from "react-export-excel";
import { toast } from "react-toastify";
import { FlexboxGrid, Table, Tooltip, Whisper, Popover } from "rsuite";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function OrderDetail({ adminAuth }) {
  const [filter, setFilter] = useState("");
  const [fetchOperaFields, setFetchOperaFields] = useState(false);

  const [orderData, setOrderData] = useState("");
  // const [currency, setCurrency] = useState("All");

  const [seasonValues, setSeasonValues] = useState([{ season: "" }]);
  const [repValues, setRepValues] = useState([{ rep: "" }]);
  const [currencyValues, setCurrencyValues] = useState([{ currency: "" }]);

  const [salesReps, setSalesReps] = useState([]);
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [loading, setLoading] = useState(false);

  const [fetchedData, setFetchedData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [displayExcelBtn, setDisplayExcelBtn] = useState(true);

  useEffect(() => {
    // testcall(adminAuth).then((response) => {
    //   console.log('test call',response);
    //   // setOrder(response[0]);
    //   // setOrderWithStyles(response);
    // })
    // .catch((e) => {
    //   console.log("test call catch", e);
    //   // setOnline(false);
    // });
    if (!salesReps || salesReps.length === 0) {
      getSalesReps(adminAuth)
        .then((response) => {
          setSalesReps(response);
          // setOrder(response[0]);
          // setOrderWithStyles(response);
        })
        .catch((e) => {
          console.log("catch", e);
          // setOnline(false);
        });
    }
  }, []);
  useEffect(() => {
    console.log("displayExcelBtn", displayExcelBtn);
  }, [displayExcelBtn]);
  function displayDownload() {
    setDisplayExcelBtn(!displayExcelBtn);
  }
  const arraySearch = (array, keyword) => {
    let searchTerm = keyword;
    if (isNaN(keyword)) {
      searchTerm = keyword.toLowerCase();
    }
    return array.filter((value) => {
      return (
        value.order_id == searchTerm ||
        value.AccNo.toLowerCase().match(new RegExp(searchTerm, "g")) ||
        value.Store.toLowerCase().match(new RegExp(searchTerm, "g"))
      );
    });
  };
  const getData = () => {
    let newExcelData = excelData;
    if (sortColumn && sortType) {
      newExcelData = excelData.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === "string") {
          x = x.charCodeAt();
        }
        if (typeof y === "string") {
          y = y.charCodeAt();
        }
        if (sortType === "asc") {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    // setExcelData(newExcelData);
    console.log("newExcelData", newExcelData);
    console.log("excelData", excelData);

    return newExcelData;
  };
  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const handleChange = async (event) => {
    const { name, value, checked } = event.target;
    console.log("changed", value);
    // console.log('name',name);

    if (name.indexOf("filter") !== -1) {
      console.log("name", name);
      setFilter(value);
      if (value.length > 0) {
        let search = await arraySearch(fetchedData, value);
        setFilterData(search);
        setExcelData(search);
      } else {
        setFilterData(fetchedData);
        setExcelData(fetchedData);
      }

      // setCount(search.length)
    } else if (name.indexOf("fetch_from_opera") !== -1) {
      if (checked) {
        setFetchOperaFields(true);
      } else {
        setFetchOperaFields(false);
      }
    }
  };

  //Currency events
  let handleCurrencychange = (i, e) => {
    let newCurrencyValues = [...currencyValues];
    newCurrencyValues[i][e.target.name] = e.target.value;
    setCurrencyValues(newCurrencyValues);
  };

  let addCurrencyFields = () => {
    setCurrencyValues([...currencyValues, { currency: "" }]);
  };

  let removeCurrencyFields = (i) => {
    let newCurrencyValues = [...currencyValues];
    newCurrencyValues.splice(i, 1);
    setCurrencyValues(newCurrencyValues);
  };
  //end

  //season events
  let handleSeasonchange = (i, e) => {
    let newSeasonValues = [...seasonValues];
    newSeasonValues[i][e.target.name] = e.target.value;
    setSeasonValues(newSeasonValues);
  };
  //season events
  // let handleCurrencychange = (e) => {
  //   setCurrency(e.target.value);
  // };
  let addSeasonFields = () => {
    setSeasonValues([...seasonValues, { season: "" }]);
  };

  let removeSeasonFields = (i) => {
    let newSeasonValues = [...seasonValues];
    newSeasonValues.splice(i, 1);
    setSeasonValues(newSeasonValues);
  };
  //season events ends

  //Reps events
  let handleRepchange = (i, e) => {
    let newSeasonValues = [...repValues];
    newSeasonValues[i][e.target.name] = e.target.value;
    setRepValues(newSeasonValues);
  };

  let addRepFields = () => {
    setRepValues([...repValues, { rep: "" }]);
  };

  let removeRepFields = (i) => {
    let newRepValues = [...repValues];
    newRepValues.splice(i, 1);
    setRepValues(newRepValues);
  };
  //Reps events ends

  function getRepsOptions() {
    let repOptions = [];
    if (salesReps && salesReps.length > 0) {
      salesReps.map((rep) => {
        let name = rep.User;
        repOptions.push({
          value: name,
          text: name,
        });
      });
    }
    return repOptions.reverse();
  }

  const handleSubmit = (event) => {
    setLoading(true);

    let seasonString = seasonValues.map((e) => "'" + e.season + "'").join(",");
    let repString = repValues.map((e) => "'" + e.rep + "'").join(",");
    let currencyString = currencyValues
      .map((e) => "'" + e.currency + "'")
      .join(",");

    let formData = {
      season: seasonString,
      currency: currencyString,
      rep: repString,
      fetch_opera_fields: fetchOperaFields,
    };
    let is_empty = 1;
    if (formData.season != "''") {
      is_empty = 0;
    }
    if (formData.rep != "''") {
      is_empty = 0;
    }
    if (formData.currency != "") {
      is_empty = 0;
    }

    if (is_empty == 1) {
      toast.error("Fill Atleast single field to fetch order", {
        autoClose: true,
      });
      setLoading(false);
      return;
    }
    getOrderDetails(formData, adminAuth)
      .then((response) => {
        console.log("response", JSON.parse(response.body));
        setFetchedData(JSON.parse(response.body));
        setFilterData(JSON.parse(response.body));
        setExcelData(JSON.parse(response.body));
        setLoading(false);

        let msg = "Report Fetched Successfully";

        toast.success(msg, {
          autoClose: true,
        });
        // setOrder(response[0]);
        // setOrderWithStyles(response);
      })
      .catch((e) => {
        console.log("catch", e);
        toast.error("Unable to Fetch Report, Try again", {
          autoClose: true,
        });
      });
    console.log("formData", formData);
  };
  const ImportedCell = ({ rowData, dataKey, ...props }) => {
    let imported = "No";
    if (rowData[dataKey] != null) {
      imported = "Yes";
    }
    if (rowData["on_stop"] == "Yes") {
      return (
        <Table.Cell {...props} style={{ color: "red" }}>
          {imported}
        </Table.Cell>
      );
    }
    return <Table.Cell {...props}>{imported}</Table.Cell>;
  };

  const ShippingCell = ({ rowData, dataKey, ...props }) => {
    let shipping = "";
    if (rowData[dataKey] != "") {
      shipping = "Yes";
    }
    if (rowData["on_stop"] == "Yes") {
      return (
        <Table.Cell {...props} style={{ color: "red" }}>
          {shipping}
        </Table.Cell>
      );
    }
    return <Table.Cell {...props}>{shipping}</Table.Cell>;
  };
  const UserCell = ({ rowData, dataKey, ...props }) => {
    let user = "";
    if (rowData[dataKey] != null) {
      let tempUser = rowData[dataKey].split("@");
      user = tempUser[0];
    }
    if (rowData["on_stop"] == "Yes") {
      return (
        <Table.Cell {...props} style={{ color: "red" }}>
          {user}
        </Table.Cell>
      );
    }
    return <Table.Cell {...props}>{user}</Table.Cell>;
  };
  const SoNumberCell = ({ rowData, dataKey, ...props }) => {
    let sono = "";
    if (rowData[dataKey] != null && rowData[dataKey] != "NA") {
      let temp = rowData[dataKey].split("-");
      sono = temp[1];
    }

    if (rowData["on_stop"] == "Yes") {
      return (
        <Table.Cell {...props} style={{ color: "red" }}>
          {sono}
        </Table.Cell>
      );
    }
    return <Table.Cell {...props}>{sono}</Table.Cell>;
  };

  const DateCell = ({ rowData, dataKey, ...props }) => {
    let date = "";
    let columnData = "";

    if (rowData[dataKey] != "NA" && rowData[dataKey] != null) {
      const dateArr = rowData[dataKey].split(",");

      if (dateArr.length > 1) {
        let uniqueArr = [...new Set(dateArr)];
        if (uniqueArr.length > 1) {
          date = "MULTI";
          const speaker = (
            <Popover title="Dates">
              {uniqueArr.map((d, index) => (
                <p key={"pop_" + index}>{d}</p>
              ))}
            </Popover>
          );
          columnData = (
            <Whisper placement="right" speaker={speaker}>
              <a>
                <b>{date.toLocaleString()}</b>
              </a>
            </Whisper>
          );
        } else {
          date = uniqueArr[0];
          columnData = date;
        }
      } else {
        if (dateArr.length === 1) {
          columnData = dateArr;
        }
      }
    }

    if (rowData["on_stop"] == "Yes") {
      return (
        <Table.Cell {...props} style={{ color: "red" }}>
          {columnData}
        </Table.Cell>
      );
    }
    return <Table.Cell {...props}>{columnData}</Table.Cell>;
  };
  const OrderDateCell = ({ rowData, dataKey, ...props }) => {
    let date = "";
    let columnData = "";

    // if(rowData['order_status'] == 'Ord/D/I' ||  rowData['order_status'] == 'Invoiced' ){
    const speaker = (
      <Popover title="">
        <p>
          <b>{"Order Date   : "}</b>
          {rowData["order_date"]}
        </p>
        <p>
          <b>{"Invoice Date : "}</b>
          {rowData["delivery_date"]}
        </p>
        <p>
          <b>{"Delivery Date: "}</b>
          {rowData["invoice_date"]}
        </p>
      </Popover>
    );
    columnData = (
      <Whisper placement="right" speaker={speaker}>
        <a>{rowData[dataKey].toLocaleString()}</a>
      </Whisper>
    );
    // }else{
    //   columnData = rowData[dataKey];
    // }
    if (rowData["on_stop"] == "Yes") {
      return (
        <Table.Cell {...props} style={{ color: "red" }}>
          {columnData}
        </Table.Cell>
      );
    }
    return <Table.Cell {...props}>{columnData}</Table.Cell>;
  };
  const AccCell = ({ rowData, dataKey, ...props }) => {
    let accNo = rowData[dataKey];
    if (rowData["on_stop"] == "Yes") {
      return (
        <Table.Cell {...props} style={{ color: "red" }}>
          {accNo}
        </Table.Cell>
      );
    }
    return <Table.Cell {...props}>{accNo}</Table.Cell>;
  };
  const StoreCell = ({ rowData, dataKey, ...props }) => {
    let store = rowData[dataKey];
    if (rowData["on_stop"] == "Yes") {
      return (
        <Table.Cell {...props} style={{ color: "red" }}>
          {store}
        </Table.Cell>
      );
    }
    return <Table.Cell {...props}>{store}</Table.Cell>;
  };
  const OrderCell = ({ rowData, dataKey, ...props }) => {
    let order_id = rowData[dataKey];
    console.log("on_stop", rowData["on_stop"]);
    if (rowData["on_stop"] == "Yes") {
      return (
        <Table.Cell {...props} style={{ color: "red" }}>
          {order_id}
        </Table.Cell>
      );
    }
    return <Table.Cell {...props}>{order_id}</Table.Cell>;
  };
  const OrderStatusCell = ({ rowData, dataKey, ...props }) => {
    let order_status = rowData[dataKey];
    console.log("on_stop", rowData["on_stop"]);
    if (rowData["on_stop"] == "Yes") {
      return (
        <Table.Cell {...props} style={{ color: "red" }}>
          {order_status}
        </Table.Cell>
      );
    }
    return <Table.Cell {...props}>{order_status}</Table.Cell>;
  };
  function get_shipping_date(col) {
    let date = "";
    let columnData = "";

    if (col.date != "NA" && col.date != null) {
      const dateArr = col.date.split(",");
      if (dateArr.length > 1) {
        let uniqueArr = [...new Set(dateArr)];
        if (uniqueArr.length > 1) {
          columnData = uniqueArr.toString();
        } else {
          date = uniqueArr[0];
          columnData = date;
        }
      }
    }
    return columnData;
  }
  function getShippingText(col) {
    let shipping = "";
    if (col.Shipping != "") {
      shipping = "Yes";
    }
    return shipping;
  }
  function getImportedText(col) {
    let imported = "No";
    if (col.Imported != null) {
      imported = "Yes";
    }
    return imported;
  }
  return (
    <div className="report_page">
      <h1>Order Status</h1>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={8}>
          <div className="season_wrapper">
            <h5>Season</h5>
            {seasonValues.map((element, index) => (
              <>
                <div className="single_season" key={"season_" + index}>
                  <SelectInput
                    name="season"
                    label=""
                    onChange={(e) => handleSeasonchange(index, e)}
                    defaultOption={"season"}
                    value={element.season}
                    options={[
                      {
                        value: "AW2020",
                        text: "AW20",
                      },
                      {
                        value: "SS2021",
                        text: "SS21",
                      },
                      {
                        value: "AW2021",
                        text: "AW21",
                      },
                      {
                        value: "SS2022",
                        text: "SS22",
                      },
                      {
                        value: "AW2022",
                        text: "AW22",
                      },
                      {
                        value: "SS2023",
                        text: "SS23",
                      },
                      {
                        value: "AW2023",
                        text: "AW23",
                      },
                    ]}
                  />
                  {index > 0 ? (
                    <FaMinus
                      className="remove_icon"
                      onClick={() => removeSeasonFields(index)}
                    />
                  ) : (
                    <BsPlus
                      className="add_icon"
                      onClick={() => addSeasonFields()}
                    />
                  )}
                </div>
              </>
            ))}
          </div>
        </FlexboxGrid.Item>

        <FlexboxGrid.Item colspan={8}>
          <h5>Currency</h5>
          {currencyValues.map((element, index) => (
            <>
              <div className="single_currency" key={"currency_" + index}>
                <SelectInput
                  name="currency"
                  label=""
                  onChange={(e) => handleCurrencychange(index, e)}
                  defaultOption={"season"}
                  value={element.currency}
                  options={[
                    {
                      value: "USD",
                      text: "USD",
                    },
                    {
                      value: "GBP",
                      text: "GBP",
                    },
                    {
                      value: "EUR",
                      text: "EUR",
                    },
                  ]}
                />
                {index > 0 ? (
                  <FaMinus
                    className="remove_icon"
                    onClick={() => removeCurrencyFields(index)}
                  />
                ) : (
                  <BsPlus
                    className="add_icon"
                    onClick={() => addCurrencyFields()}
                  />
                )}
              </div>
            </>
          ))}
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          {salesReps ? (
            <div className="reps_wrapper">
              <h5>Rep</h5>
              {repValues.map((element, index) => (
                <div className="single_rep" key={"rep_" + index}>
                  <SelectInput
                    name="rep"
                    label=""
                    onChange={(e) => handleRepchange(index, e)}
                    defaultOption={"rep1"}
                    value={element.rep}
                    options={getRepsOptions()}
                  />
                  {index > 0 ? (
                    <FaMinus
                      className="remove_icon"
                      onClick={() => removeRepFields(index)}
                    />
                  ) : (
                    <BsPlus
                      className="add_icon"
                      onClick={() => addRepFields()}
                    />
                  )}
                </div>
              ))}
            </div>
          ) : null}
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={24}>
          <a
            onClick={handleSubmit}
            //   disabled={saving}
            className="btn btn-primary btn-next btn-link-custom_ margin0"
          >
            Submit
          </a>
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <FlexboxGrid>
        <FlexboxGrid.Item colspan={24}>
          <div
            className="form-group custom_group"
            style={{ marginTop: "10px" }}
          >
            <div className="field">
              <input
                type="text"
                name="filter"
                className="form-control custom_filter"
                placeholder="Filter"
                value={filter}
                onChange={handleChange}
              />
            </div>
          </div>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={24}>
          {excelData && excelData.length > 0 ? (
            <div className="download_btn" style={{ marginBottom: "10px" }}>
              <ExcelFile
                filename={"Download"}
                element={<button>Export Data</button>}
              >
                <ExcelSheet data={excelData} name="Order">
                  <ExcelColumn label="Account" value="AccNo" />
                  <ExcelColumn label="Store" value="Store" />
                  <ExcelColumn
                    label="Date"
                    value={(col) => get_shipping_date(col)}
                  />
                  <ExcelColumn label="Order#" value="order_id" />
                  <ExcelColumn
                    label="Shipping Instructions"
                    value={(col) => getShippingText(col)}
                  />
                  <ExcelColumn label="User" value="User" />
                  <ExcelColumn
                    label="Imported"
                    value={(col) => getImportedText(col)}
                  />
                  <ExcelColumn label="SO Number" value="so_number" />
                  <ExcelColumn label="Order Status" value="order_status" />
                  <ExcelColumn label="Due Date" value="due_date" />
                  <ExcelColumn label="Order Date" value="order_date" />
                  <ExcelColumn label="Delivery Date" value="delivery_date" />
                  <ExcelColumn label="Invoice Date" value="invoice_date" />
                  <ExcelColumn label="On Stop" value="on_stop" />
                </ExcelSheet>
              </ExcelFile>
            </div>
          ) : null}
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={24}>
          {filterData && filterData.length > 0 ? (
            <div style={{ border: "1px solid #ddd", height: "auto" }}>
              <Table
                wordWrap
                virtualized
                // height={300}
                autoHeight={"true"}
                data={getData()}
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSortColumn}
                locale={{ emptyMessage: "No Order Data found" }}
                loading={loading}
                onRowClick={(data) => {
                  // console.log(data);
                }}
              >
                <Table.Column flexGrow={1} align="center" sortable>
                  <Table.HeaderCell>
                    <b>AccNo</b>
                  </Table.HeaderCell>
                  <AccCell dataKey="AccNo" />
                </Table.Column>

                <Table.Column flexGrow={3} sortable fixed>
                  <Table.HeaderCell>
                    <b>Store</b>
                  </Table.HeaderCell>
                  <StoreCell dataKey="Store" />
                </Table.Column>
                <Table.Column flexGrow={1}>
                  <Table.HeaderCell>
                    <Whisper
                      placement="top"
                      speaker={<Popover title="">Customer Date</Popover>}
                    >
                      <a>
                        <b>Date</b>
                      </a>
                    </Whisper>
                  </Table.HeaderCell>
                  <DateCell dataKey="date" />
                </Table.Column>
                <Table.Column width={80} sortable>
                  <Table.HeaderCell>
                    <b>Order#</b>
                  </Table.HeaderCell>
                  <OrderCell dataKey="order_id" />
                </Table.Column>
                <Table.Column width={60} sortable>
                  <Table.HeaderCell>
                    <Whisper
                      placement="top"
                      speaker={
                        <Popover title="">Shipping Instructions</Popover>
                      }
                    >
                      <a>
                        <b>Inst</b>
                      </a>
                    </Whisper>
                  </Table.HeaderCell>
                  <ShippingCell dataKey="Shipping" />
                </Table.Column>
                <Table.Column width={110} sortable>
                  <Table.HeaderCell>
                    <b>User</b>
                  </Table.HeaderCell>
                  <UserCell dataKey="User" />
                </Table.Column>
                <Table.Column flexGrow={1} sortable>
                  <Table.HeaderCell>
                    <b>Imported</b>
                  </Table.HeaderCell>
                  <ImportedCell dataKey="Imported" />
                </Table.Column>

                <Table.Column flexGrow={1} sortable>
                  <Table.HeaderCell>
                    <b>SO.No</b>
                  </Table.HeaderCell>
                  <SoNumberCell dataKey="so_number" />
                </Table.Column>
                <Table.Column flexGrow={1} sortable>
                  <Table.HeaderCell>
                    <Whisper
                      placement="top"
                      speaker={<Popover title="">Order Status</Popover>}
                    >
                      <a>
                        <b>Status</b>
                      </a>
                    </Whisper>
                  </Table.HeaderCell>
                  <OrderStatusCell dataKey="order_status" />
                </Table.Column>
                <Table.Column flexGrow={1}>
                  <Table.HeaderCell>
                    <b>Due Date</b>
                  </Table.HeaderCell>
                  {/* <Table.Cell dataKey="order_date" /> */}
                  <OrderDateCell dataKey="due_date" />
                </Table.Column>
              </Table>
            </div>
          ) : null}
        </FlexboxGrid.Item>
      </FlexboxGrid>
      {orderData ? <div className="download_btn"></div> : null}
    </div>
  );
}
function mapStateToProps(state) {
  return {
    adminAuth: state.adminAuth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
