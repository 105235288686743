import { handleResponse, handleError } from "./apiUtils";
const base_url = process.env.REACT_APP_BASE_URL;

function extractData(res) {
  return res.json().then((response) => {
    if (response) {
      return JSON.parse(response.body);
    } else {
      return null;
    }
  });
}

export function submitOrder(
  customer,
  cart,
  totals,
  addresses,
  currency,
  pos,
  comments,
  po,
  shipping,
  signature,
  season,
  auth,
  user,
  currentSeason,
  atOnce,
  discount,
  images,
  isDraft,
  promo
) {
  let currencyLabel = "";

  switch (currency) {
    case "£":
      currencyLabel = "GBP";
      break;
    case "€":
      currencyLabel = "EUR";
      break;
    case "$":
      currencyLabel = "USD";
      break;
    default:
      currencyLabel = "GBP";
      break;
  }

  return fetch(base_url + "/order", {
    method: "POST",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      cart: cart,
      customer: customer,
      totals: totals,
      addresses: addresses,
      currency: currencyLabel,
      currencyLabel: currency,
      pos: pos,
      comments: comments,
      po: po,
      shipping: shipping,
      discount: discount,
      signature: signature,
      season: season,
      user: user,
      currentSeason: currentSeason,
      atOnce: atOnce,
      images: images,
      isDraft: isDraft,
      promo: promo,
    }),
  })
    .then(handleResponse)
    .then((res) => {
      return res.json().then((response) => {
        if (response) {
          return response;
        } else {
          return null;
        }
      });
    })
    .catch(handleError);
}

export function submitChanges(
  cart,
  customer,
  totals,
  currency,
  comments,
  po,
  shipping,
  auth,
  user,
  season,
  discount,
  defaultFileList,
  originalOrderId,
  addresses,
  is_draft,
  atOnce,
  promo
) {
  let currencyLabel = "";

  switch (currency) {
    case "£":
      currencyLabel = "GBP";
      break;
    case "€":
      currencyLabel = "EUR";
      break;
    case "$":
      currencyLabel = "USD";
      break;
    default:
      currencyLabel = "GBP";
      break;
  }

  return fetch(base_url + "/order/amend", {
    method: "POST",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      cart,
      customer,
      totals,
      currency: currencyLabel,
      comments,
      po,
      shipping,
      auth,
      user,
      season,
      discount,
      defaultFileList,
      originalOrderId,
      addresses,
      is_draft,
      atOnce,
      promo,
    }),
  })
    .then(handleResponse)
    .then(extractData)
    .catch(handleError);
}

export function getOrders(auth, user, is_draft = 0) {
  return fetch(base_url + "/orders?user=" + user + "&is_draft=" + is_draft, {
    method: "GET",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
  })
    .then(handleResponse)
    .then((response) => {
      return response.json();
    })
    .catch(handleError);
}

export function getOrdersAmends(auth, user) {
  return fetch(base_url + "/orders/amends?user=" + user, {
    method: "GET",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
  })
    .then(handleResponse)
    .then((response) => {
      return response.json();
    })
    .catch(handleError);
}

export function getOrder(id, auth) {
  return fetch(base_url + "/order?id=" + id, {
    method: "GET",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
  })
    .then(handleResponse)
    .catch(handleError);
}
export function getOrderById(id, auth) {
  return fetch(base_url + "/order?id=" + id, {
    method: "GET",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
  })
    .then(handleResponse)
    .then((response) => {
      return response.json();
    })
    .catch(handleError);
}
export function getOrderBySearch(searchTerm, auth) {
  return fetch(base_url + "/getOrder", {
    method: "POST",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      searchTerm: searchTerm,
    }),
  })
    .then(handleResponse)
    .then((response) => {
      return response.json();
    })
    .catch(handleError);
}
export function cancelOrder(id, auth) {
  return fetch(base_url + "/order/cancel", {
    method: "POST",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      orderId: id,
    }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function confirmOrder(id, auth) {
  return fetch(base_url + "/order/confirm", {
    method: "POST",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      orderId: id,
    }),
  })
    .then(handleResponse)
    .catch(handleError);
}
export function deleteOrderById(id, auth) {
  return fetch(base_url + "/order/delete", {
    method: "POST",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      orderId: id,
    }),
  })
    .then(handleResponse)
    .then((response) => {
      return response.body;
    })
    .catch(handleError);
}
export function getOrderReport(formData, auth) {
  return fetch(base_url + "/report/get", {
    method: "POST",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  })
    .then(handleResponse)
    .then((response) => {
      return response.json();
    })
    .catch(handleError);
}
export function getOrderDetails(formData, auth) {
  return fetch(base_url + "/report/order/detail", {
    method: "POST",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  })
    .then(handleResponse)
    .then((response) => {
      return response.json();
    })
    .catch(handleError);
}
export function getSalesReps(auth) {
  return fetch(base_url + "/customer/get", {
    method: "GET",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
  })
    .then(handleResponse)
    .then((response) => {
      return response.json();
    })
    .catch(handleError);
}
export function getSavedQueries(auth, is_rep = 0) {
  console.log("getSavedQueries");
  return fetch(base_url + "/queries/get?is_rep=" + is_rep, {
    method: "GET",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
  })
    .then(handleResponse)
    .then((response) => {
      return response.json();
    })
    .catch(handleError);
}
export function getQueryById(id, auth, is_rep = 0) {
  console.log("getQueryById");
  return fetch(base_url + "/query?id=" + id + "&is_rep=" + is_rep, {
    method: "GET",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
  })
    .then(handleResponse)
    .then((response) => {
      return response.json();
    })
    .catch(handleError);
}
export function deleteQueryById(id, auth) {
  return fetch(base_url + "/query/delete", {
    method: "POST",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      queryId: id,
    }),
  })
    .then(handleResponse)
    .then((response) => {
      return response.body;
    })
    .catch(handleError);
}
export function insertPlanner(formData, auth) {
  return fetch(base_url + "/order/planner/insert", {
    method: "POST",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  })
    .then(handleResponse)
    .then((response) => {
      return response.json();
    })
    .catch(handleError);
}
export function updatePlanner(formData, auth) {
  return fetch(base_url + "/order/planner/update", {
    method: "POST",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  })
    .then(handleResponse)
    .then((response) => {
      return response.json();
    })
    .catch(handleError);
}
export function getPlanners(auth) {
  return fetch(base_url + "/order/planner/get", {
    method: "GET",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
  })
    .then(handleResponse)
    .then((response) => {
      return response.json();
    })
    .catch(handleError);
}
export function getPlannerById(id, auth) {
  return fetch(base_url + "/order/planner?id=" + id, {
    method: "GET",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
  })
    .then(handleResponse)
    .then((response) => {
      return response.json();
    })
    .catch(handleError);
}
export function deletePlannerById(id, auth) {
  return fetch(base_url + "/order/planner/delete", {
    method: "POST",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      plannerId: id,
    }),
  })
    .then(handleResponse)
    .then((response) => {
      return response.body;
    })
    .catch(handleError);
}
export function testcall(auth) {
  return fetch(base_url + "/testing", {
    method: "GET",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
  })
    .then(handleResponse)
    .then((response) => {
      return response.json();
    })
    .catch(handleError);
}

// Returns API
export function insertReturn(formData, auth) {
  return fetch(base_url + "/order/return/insert", {
    method: "POST",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  })
    .then(handleResponse)
    .then((response) => {
      return response.json();
    })
    .catch(handleError);
}
export function getReturns(auth) {
  return fetch(base_url + "/order/returns", {
    method: "GET",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
  })
    .then(handleResponse)
    .then((response) => {
      return response.json();
    })
    .catch(handleError);
}
export function getReturnById(id, auth) {
  console.log("getReturnById", id);
  return fetch(base_url + "/order/return?id=" + id, {
    method: "GET",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
  })
    .then(handleResponse)
    .then((response) => {
      return response.json();
    })
    .catch(handleError);
}
export function getExistingReturn(id, auth) {
  console.log("getExistingReturn", id);
  return fetch(base_url + "/order/existingReturn?id=" + id, {
    method: "GET",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
  })
    .then(handleResponse)
    .then((response) => {
      return response.json();
    })
    .catch(handleError);
}
